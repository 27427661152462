<template>
  <v-dialog @click:outside="closeDialog" v-model="datetimeDialog" :persistent="persistent" width="290px"
    :fullscreen="fullscreen">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field :value="datetimeFormatted" hide-details readonly v-bind="{ ...attrs, ...$attrs }" v-on="on"
        class="text-caption" height="20">
        <template v-slot:prepend>
          <v-icon size="20" class="mt-1 mr-n1">mdi-calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card class="t-datetime-picker white">
      <v-toolbar height="45" color="primary" dark flat>
        <v-tabs v-model="tab" grow height="45" color="secondary">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab href="#date" title="Date">
            <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab href="#time" title="Time">
            <v-icon>mdi-clock</v-icon>
          </v-tab>
          <v-btn depressed text class="rounded-0" height="45" title="Save" @click="closeDialog">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item value="date">
          <v-date-picker v-model="date" color="accent2" class="rounded-0" @input="tab = 'time'" full-width> </v-date-picker>
        </v-tab-item>
        <v-tab-item value="time">
          <v-time-picker :key="tab" v-model="time" color="accent2" class="rounded-0" full-width ampm-in-title @click:minute="closeDialog">
          </v-time-picker>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.t-datetime-picker {
  .v-tabs-slider-wrapper {
    top: 0;
  }

  .v-picker__title {
    height: 90px;
  }

  .v-time-picker-title__time * {
    font-size: 60px;
    height: 60px;
  }

  .v-picker__body {
    height: 290px;
  }

  .v-tabs-items {
    height: 380px;
  }
}
</style>

<script>
import dayjs from "dayjs";

export default {
  props: {
    value: {
      type: String
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default: "MM/DD/YYYY"
    },
    timeFormat: {
      type: String,
      default: "hh:mm A"
    }
  },
  data: () => ({
    date: null,
    time: null,
    tab: false,
    datetimeDialog: false
  }),
  computed: {
    datetimeFormatted() {
      return this.value ? dayjs(this.value).format(`${this.dateFormat} ${this.timeFormat}`) : "";
    }
  },
  watch: {
    datetimeDialog: function (val) {
      // load into separated models as dialog opens
      if (val && this.value) {
        this.date = dayjs(this.value).format("YYYY-MM-DD");

        this.time = dayjs(this.value).format("HH:mm");
      }
    }
  },
  methods: {
    closeDialog() {
      this.datetimeDialog = false;
      this.$emit("input", dayjs(`${this.date} ${this.time}`, "YYYY-MM-DD HH:mm").toISOString());

      // prevents animate to date tab when closing
      setTimeout(() => (this.tab = "date"), 300);
    }
  }
}
</script>