<template>
  <v-container fluid>
    <div class="d-flex justify-space-between align-center mb-2">
      <div class="flex-grow-1 flex-shrink-0">
        <PlayerControls :showId="showId" :ind="ind" :episode="episode" :artwork-thumbnail="artworkThumbnail"
          :artwork-small="artworkSmall" :artwork-medium="artworkMedium" :metadata="metadata" :is-playing="isPlaying"
          :current-segment="currentSegment" :total-segments="totalSegments" :blob-server="blobServer"
          :loading="streamLoading" :levels="hls.levels" :available-levels="availableLevels"
          :current-level="hls.currentLevel" :select-level="hls.selectLevel" @selectedlevel="selectBitrate" @play="play"
          @pause="pause" @backward="backward" @forward="forward" @rewind="rewind" @fastforward="fastForward"
          @seekreq="seek" class="mb-2" />
      </div>
      <div class="flex-grow-0 flex-shrink-1">
        <v-btn color="success" dark large @click="dialogs.confirm.state = true" :disabled="approveButtonDisabled"
          class="ml-5 mb-2">
          Approve Episode
        </v-btn>

        <v-btn color="error" dark large @click="dialogs.reject.state = true" :disabled="approveButtonDisabled"
          class="ml-5 mb-2">
          Reject Episode
        </v-btn>
      </div>
    </div>

    <v-expansion-panels v-model="panels" accordion multiple class="mb-5">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="title font-weight-light">
            <v-icon class="mr-2">mdi-playlist-music</v-icon>Episode Info
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <div class="d-flex justify-space-between mb-3">
            <v-card color="primary" class="mr-5 flex-grow-1 flex-shrink-1" tile dark>
              <v-card-title class="pt-2">{{ show.episode.longTitle }}</v-card-title>
              <v-card-subtitle>{{ show.longTitle }}</v-card-subtitle>
              <v-card-text class="white--text">

                <v-simple-table class="primary table-episode-info">
                  <tr>
                    <th class="text-left pr-5" style="width: 140px">Episode Medium Title</th>
                    <td>{{ show.episode.mediumTitle }}</td>
                  </tr>
                  <tr>
                    <th class="text-left pr-5">Episode Long Title</th>
                    <td>{{ show.episode.longTitle }}</td>
                  </tr>
                  <tr>
                    <th class="text-left pr-5">Episode Desc</th>
                    <td>{{ show.episode.shortDescription }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">&nbsp;</td>
                  </tr>
                  <tr>
                    <th class="text-left pr-5">Series Title</th>
                    <td>{{ show.longTitle }}</td>
                  </tr>
                  <tr>
                    <th class="text-left pr-5">Series Short Desc</th>
                    <td>{{ show.shortDescription }}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Series Long Desc</th>
                    <td>{{ show.longDescription }}</td>
                  </tr>
                </v-simple-table>

                <!-- <pre>{{ show.episode }}</pre> -->
              </v-card-text>
            </v-card>

            <v-card color="primary" class="flex-grow-0 flex-shrink-0" tile dark>
              <v-card-title class="pt-2">Episode Details</v-card-title>
              <v-card-text class="white--text">
                <v-simple-table class="primary white--text text-captions">
                  <tr>
                    <th class="text-left">Episode State</th>
                    <td colspan="2" class="px-1">{{ episodeStateCode }}</td>
                  </tr>
                  <tr v-if="episodeStateCode === 'Published' && episodePublishedBy">
                    <th class="text-left">Published By</th>
                    <td colspan="2" class="px-1">{{ episodePublishedBy }}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Episode ID</th>
                    <td colspan="2" class="px-1">{{ episodeId }}</td>
                  </tr>
                  <!-- <tr>
                    <th class="text-left">Show ID</th>
                    <td colspan="2" class="px-1">{{ showId }}</td>
                  </tr> -->
                  <tr>
                    <th class="text-left">Reference ID</th>
                    <td colspan="2" class="px-1">{{ referenceId }} <v-btn small icon title="Copy to Clipboard"
                        @click="copyToClipboard(referenceId)"><v-icon small>mdi-content-copy</v-icon></v-btn></td>
                  </tr>
                  <tr>
                    <th class="text-left">Air Start Time</th>
                    <td colspan="2" class="px-1">{{ dayjs(airStartTime).format('M/D/YYYY hh:mm A') }}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Air End Time</th>
                    <td colspan="2" class="px-1">{{ dayjs(airEndTime).format('M/D/YYYY hh:mm A') }}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Embargo Time</th>
                    <td colspan="2" class="px-1">
                      <DateTimePicker v-model="embargoTimePicker" dark filled dense prepend-icon="mdi-clock" readonly>
                      </DateTimePicker>
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left pr-2">Expiration Time</th>
                    <td class="px-1 pb-1">
                      <DateTimePicker v-model="expirationTimePicker" dark filled dense prepend-icon="mdi-clock" readonly>
                      </DateTimePicker>
                    </td>
                    <td class="pl-1">
                      <v-btn color="error" small @click="setExpirationNow"><v-icon x-small
                          class="mr-1">mdi-clock</v-icon>Expire</v-btn>
                    </td>
                  </tr>
                </v-simple-table>

                <div class="d-flex justify-space-between mt-5">
                  <v-btn dark color="accent3" small
                    :href="`${cloudViewURL}?ReturnUrl=${this.cloudView.path}/Episode/Details?episodeId=${episodeId}&actionType=edit`">
                    <v-icon small class="mr-1">mdi-cloud</v-icon>
                    CloudView
                  </v-btn>

                  <v-btn dark color="green" small @click="saveEpisodeDetails">
                    Save
                    <v-icon small class="mr-n1">mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>

            <!-- <div class="flex-grow-0 flex-shrink-0 accent white--text pa-5">
              <div class="text-h6 mb-2 mt-n2">Episode Details</div>
            </div> -->
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :disabled="!hasCaptions" :class="enableCaptions ? '' : 'hidden'">
        <v-expansion-panel-header>
          <div class="title font-weight-light">
            <v-icon class="mr-2">mdi-closed-caption</v-icon>Closed Captions
            <v-progress-circular indeterminate color="accent2" size="24" width="2" class="ml-3"
              v-if="captionsLoading"></v-progress-circular>

            <span class="text-caption text-uppercase font-italic ml-3" v-else-if="!hasCaptions">Captions are not available
              for this episode</span>
          </div>
          <div>
            <v-icon>mdi-closed-caption</v-icon> {{ ccRequired ? 'Required' : 'Not Required'
            }}<!--&nbsp;&nbsp;&nbsp;&nbsp;Mode: ---->
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <div class="d-flex flex-row justify-space-between">
            <div class="flex-shrink-0">
              <audio ref="videoPlayer" class="video-js vjs-controls-disabled" preload="auto" height="450" width="450">
                <p class="vjs-no-js">
                  To view this video please enable JavaScript, and consider
                  upgrading to a web browser that
                  <a href="https://videojs.com/html5-video-support/" target="_blank">
                    supports HTML5 video
                  </a>
                </p>
              </audio>
            </div>
            <div class="flex-grow-1">
              <Captions :bus="bus" :blob-server="blobServerUrl" :showId="showId" :ind="ind" :episodeId="episodeId"
                :currentTime="currentTime" :showInfo="show" :jwtToken="jwtToken" :jwtSource="jwtSource" @seekreq="seek"
                @updateCaptions="updateCaptions" @hasCaptions="updateHasCaptions" v-if="enableCaptions" />
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="title font-weight-light">
            <v-icon class="mr-2">mdi-chart-timeline</v-icon>Segments
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <div>
            <div class="d-flex justify-space-between mb-3">
              <div class="flex-grow-0 flex-shrink-1 text-center mr-5">
                <v-img :src="`${artworkSmall.replace(
                  '_m',
                  '_s'
                )}`" height="170" width="170" v-if="artworkSmall"></v-img>
              </div>
              <div class="flex-grow-1 flex-shrink-1 text-wrap text-caption">
                <v-card dark tile color="primary" style="min-height: 170px">
                  <v-card-title class="subtitle-1 pt-2 pl-2 pb-1 d-flex justify-space-between">
                    <div>
                      <v-icon class="mr-2">mdi-account-music</v-icon>Artist Biography
                    </div>
                    <v-btn small icon @click="toggleBioExpand()" v-if="bio">
                      <v-icon small v-if="bioExpand">mdi-arrow-collapse</v-icon>
                      <v-icon small v-else>mdi-arrow-expand</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text
                    :class="bioExpand ? 'bio-text expanded overflow-y-auto white--text text-justify' : 'bio-text overflow-y-auto white--text text-justify'">
                    <span v-text="bio" v-if="bio"></span>
                    <div class="font-italic mt-10 text-center grey--text" v-else>Bio is unavailable for this segment.
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <v-data-table v-model="selectedRows" :loading="loading" single-select :headers="headers"
              :items="show.episode.segment" item-key="shortId" :items-per-page="-1" disable-sort hide-default-footer dense
              @dblclick:row="selectCut" class="flex-grow-1 flex-shrink-0 text-no-wrap">
              <template v-slot:item.programID>
                {{ show.episode.shortId }}
              </template>

              <template v-slot:item.program>
                {{ show.episode.longTitle }}
              </template>

              <template v-slot:item.channelID>
                {{ show.channel.contentId }}
              </template>

              <template v-slot:item.channelName>
                {{ show.channel.title }}
              </template>

              <template v-slot:item.channelNumber>
                {{ channelNumber }}
              </template>

              <template v-slot:item.shortId="{ item }">
                {{ item.shortId.toString().padStart(2, "0") }}
              </template>

              <template v-slot:item.cut.scheduling.startTime="{ item }">
                <small>{{
                  dayjs(item.cut.scheduling.startTime).format("MM/D h:mm:ss A")
                }}</small>
              </template>

              <template v-slot:item.cut.scheduling.duration="{ item }">
                <small>{{
                  dayjs
                    .duration(item.cut.scheduling.duration, "seconds")
                    .format("HH:mm:ss")
                }}</small>
              </template>
            </v-data-table>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-snackbar v-model="snackbar.state" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.content }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.state = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogs.alert.state" max-width="500" @click:outside="dialogs.alert.state = false">
      <v-card color="primary" dark>
        <v-card-title class="d-flex justify-space-between">
          Alert
          <v-btn icon @click="dialogs.alert.state = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <span v-text="dialogs.alert.message"></span>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="dialogs.alert.state = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.confirm.state" max-width="500" @click:outside="cleanupConfirmDialog">
      <v-card color="primary" dark>
        <v-card-title class="d-flex justify-space-between">
          Confirm
          <v-btn icon @click="cleanupConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          Are you ready to approve this episode?
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn color="error" @click="cleanupConfirmDialog">Cancel</v-btn>
          <v-btn color="accent" dark @click="confirmApprove(false)" v-if="enableCaptions && hasCaptions">Episode
            Only</v-btn>
          <v-btn color="success" @click="confirmApprove(true)" v-if="enableCaptions && hasCaptions">Episode +
            <v-icon>mdi-closed-caption</v-icon></v-btn>

          <v-btn color="success" dark @click="confirmApprove(false)" v-else>Approve</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.reject.state" max-width="700" @click:outside="cleanupRejectDialog">
      <v-card color="primary" dark>
        <v-card-title class="d-flex justify-space-between">
          Reject Episode
          <v-btn icon @click="cleanupRejectDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="rejectForm" v-model="dialogs.reject.valid">
            <v-select v-model="dialogs.reject.rejectReason" :items="dialogs.reject.rejectReasons"
              label="Reason for Rejection" outlined clearable :rules="[(v) => !!v || 'Rejection Reason is required']"
              required></v-select>

            <v-textarea v-model="dialogs.reject.rejectDetails" label="Rejection Details"
              hint="Please specify where in the episode the problem is first observed" counter="1200" persistent-hint
              outlined :rules="[
                (v) => !!v || 'Rejection Details are required',
                (v) =>
                  (v || '').length <= 1200 ||
                  'Rejection Details must be 1200 characters or less',
              ]" :required="rejectDetailsRequired" v-if="rejectDetailsRequired"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogs.reject.state = false">Cancel</v-btn>
          <v-btn color="error" @click="confirmReject">
            Reject Episode
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.hidden {
  display: none;
}

.bio-text {
  height: 130px;
}

.bio-text.expanded {
  height: auto;
}

.table-episode-info th {
  white-space: nowrap;
  vertical-align: top;
}
</style>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import axios from "axios";
import { find, isArray, isFunction } from "lodash";
import { XMLParser } from "fast-xml-parser";
import { useTitle, onKeyUp } from "@vueuse/core";
import Vue from "vue";
import PlayerControls from "@/components/PlayerControls";
import Captions from "@/components/Captions";
import DateTimePicker from "@/components/DateTimePicker";
import videojs from "video.js";
import "videojs-contrib-quality-levels";

window.HELP_IMPROVE_VIDEOJS = false;

dayjs.extend(duration);

export default {
  name: "QAPlayer",

  props: ["darkMode", "showId", "ind", "episode", "referenceId", "episodeState", "episodeStateCode", "episodeStateDesc", "episodePublishedBy", "airStartTime", "airEndTime", "embargoTime", "expirationTime", "ccRequired"],

  components: {
    PlayerControls,
    Captions,
    DateTimePicker
  },

  data: () => ({
    bus: new Vue(),
    jwtToken: null,
    jwtSource: null,
    bitrate: null,
    version: 2,
    blobServer: window.__env.blobServer,
    qaApproveRejectServer: window.__env.qaApproveRejectURL,
    cloudView: window.__env.cloudView,
    autoPlay: window.__env.autoPlayEpisode,
    currentSegment: null,
    currentTime: 0,
    hls: {
      levels: [],
      currentLevel: null,
      selectLevel: -1,
    },
    enableCaptions: window.__env.enableCaptions,
    hasCaptions: false,
    panels: [],
    selectedRows: [],
    headers: [
      { value: "cut.scheduling.startTime", text: "Start Time" },
      { value: "cut.scheduling.duration", text: "Duration" },
      { value: "cut.artist.title", text: "Artist" },
      { value: "cut.title", text: "Title" },
      { value: "cut.album.title", text: "Album" },
      { value: "cut.composer", text: "Composer" },
      { value: "cut.id", text: "ID", sortable: false },
      { value: "programID", text: "programID", sortable: false },
      { value: "cut.siriusXMId", text: "SiriusXmId" },
      { value: "shortId", text: "SegmentID" },
      // {value: 'program', text: 'Program', sortable: false},
      // {value: 'channelID', text: 'ChannelID', sortable: false},
      // {value: 'channelName', text: 'ChannelName', sortable: false},
      // {value: 'channelNumber', text: 'ChannelNumber', sortable: false},
    ],
    items: [],
    approveButtonDisabled: true, //disabled on load, determine if episode can be approved/rejected before enabling it
    embargoPickerMenu: false,
    embargoTimePicker: null,
    expirationPickerMenu: false,
    expirationTimePicker: null,
    dialogs: {
      alert: {
        state: false,
        message: null,
      },
      confirm: {
        state: false,
      },
      reject: {
        state: false,
        rejectReasons: [
          "Unexplained silence",
          "Misaligned spot blocks",
          "Static or unexpected noise",
          "Unexplained missing or wrong audio cut(s)",
          "Known error in submission, plan to redo episode",
          "Other - please be as specific as possible",
        ],
        rejectReason: null,
        rejectDetails: null,
      },
    },
    snackbar: {
      state: false,
      color: null,
      content: "",
      timeout: 5000,
    },
    firstPlay: true,
    isPlaying: false,
    loading: false,
    streamLoading: false,
    captionsLoading: false,
    artworkThumbnail: null,
    artworkSmall: null,
    artworkMedium: null,
    bio: null,
    bioExpand: false,
    show: {
      shortId: null,
      mediumTitle: null,
      longTitle: null,
      shortDescription: null,
      longDescription: null,
      channel: {
        busCode: null,
        channelContentType: null,
        channelNumber: [],
        contentId: null,
        title: null,
      },
      episode: {
        dmcaInfo: {
          backSkipDur: null,
          channelContentType: null,
          fwdSkipDur: null,
          irNavClass: null,
          maxBackSkips: null,
          maxFwdSkips: null,
          playOnSelect: null,
        },
        drmInfo: {
          activationDateTime: null,
          expirationDateTime: null,
          numberOfPlays: null,
          recordRestriction: null,
          sharedFlag: null,
        },
        firstShowing: null,
        lastShowing: null,
        longDescription: null,
        shortDescription: null,
        longTitle: null,
        shortId: null,
        mediumTitle: null,
        originalAirDate: null,
        repeat: null,
        scheduling: {
          duration: null,
          startTime: null,
        },
        segment: [],
      },
    },
    player: null,
    videoOptions: {
      autoplay: false,
      controls: true,
      sources: [],
    },
  }),

  computed: {
    episodeId() {
      return this.episode;
    },

    cloudViewURL() {
      return (
        (this.cloudView.https ? "https://" : "http://") +
        this.cloudView.host +
        ((!this.cloudView.https && this.cloudView.port !== 80) ||
          (this.cloudView.https && this.cloudView.port !== 443)
          ? ":" + this.cloudView.port
          : "") +
        this.cloudView.path
      );
    },

    rejectDetailsRequired() {
      return (
        this.dialogs.reject.rejectReason ===
        "Other - please be as specific as possible" ||
        this.dialogs.reject.rejectReason === "Unexplained silence" ||
        this.dialogs.reject.rejectReason === "Misaligned spot blocks" ||
        this.dialogs.reject.rejectReason === "Static or unexpected noise" ||
        this.dialogs.reject.rejectReason ===
        "Unexplained missing or wrong audio cut(s)"
      );
    },

    availableLevels() {
      this.hls.levels.forEach((level, idx) => {
        level.index = idx;

        switch (level.bitrate) {
          case 35200:
          case 39600:
            level.name = "32k";
            break;
          case 70400:
          case 74800:
            level.name = "64k";
            break;
          case 105600:
            level.name = "96k";
            break;
          case 281600:
          case 286000:
            level.name = "256k";
            break;
          default:
            level.name = level.bitrate / 1000 + "k";
        }
      });
      return Array().concat({ index: -1, name: "Automatic" }, this.hls.levels);
    },

    blobServerUrl() {
      return (
        (this.blobServer.https ? "https://" : "http://") +
        this.blobServer.host +
        ((!this.blobServer.https && this.blobServer.port !== 80) ||
          (this.blobServer.https && this.blobServer.port !== 443)
          ? ":" + this.blobServer.port
          : "") +
        this.blobServer.path
      );
    },

    qaApproveRejectURL() {
      return (
        (this.qaApproveRejectServer.https ? "https://" : "http://") +
        this.qaApproveRejectServer.host +
        ((!this.qaApproveRejectServer.https &&
          this.qaApproveRejectServer.port !== 80) ||
          (this.qaApproveRejectServer.https &&
            this.qaApproveRejectServer.port !== 443)
          ? ":" + this.qaApproveRejectServer.port
          : "") +
        this.qaApproveRejectServer.path
      );
    },

    totalSegments() {
      return this.show.episode.segment.length;
    },

    metadata() {
      if (
        this.show.episode &&
        this.show.episode.segment[this.currentSegment] &&
        this.show.episode.segment[this.currentSegment].cut
      ) {
        return {
          artist:
            this.show.episode.segment[this.currentSegment].cut.artist.title,
          title: this.show.episode.segment[this.currentSegment].cut.title,
          album: this.show.episode.segment[this.currentSegment].cut.album.title,
          currentTime: this.currentTime,
          buffered:
            this.player && this.player.duration && this.player.buffered
              ? this.player.buffered().end(0)
              : 0,
          duration: this.player ? this.player.duration() : 0,
          currentLevel: this.hls.currentLevel,
        };
      } else {
        return {};
      }
    },

    dayjs() {
      return dayjs;
    },

    channelNumber() {
      return find(this.show.channel.channelNumber, { type: "SIRIUS IP" })
        .number;
    },
  },

  watch: {
    episodeState() {
      // console.log('episodeState changed:', this.episodeState);
      this.approveButtonDisabled = (this.episodeState !== 5);
    },

    currentTime() {
      this.checkSegment();
    },

    async currentSegment() {
      if (!this.show.episode.segment[this.currentSegment]) return;

      useTitle(
        `${this.metadata.artist} - ${this.metadata.title}: AOD QC Player`
      );

      this.selectedRows = [this.show.episode.segment[this.currentSegment]];

      const artworkThumbnail = find(
        this.show.episode.segment[this.currentSegment].cut.album.creativeArt,
        {
          type: "IMAGE",
          size: "THUMBNAIL",
        }
      );

      const artworkSmall = find(
        this.show.episode.segment[this.currentSegment].cut.album.creativeArt,
        {
          type: "IMAGE",
          size: "SMALL",
        }
      );

      const artworkMedium = find(
        this.show.episode.segment[this.currentSegment].cut.album.creativeArt,
        {
          type: "IMAGE",
          size: "MEDIUM",
        }
      );

      // const regex = /([A-Z]{4}-\d{9})-?(\d{3})?/g;
      // const match = regex.exec(artworkThumbnail.url);
      // if (match && match[0]) artworkThumbnail.assetId = match[0];

      this.artworkThumbnail = artworkThumbnail.url.replace(
        "artwork",
        `${this.blobServerUrl}/${this.showId}/${this.ind}/EPISODE_${this.episodeId}/artwork.1.dar`
      );
      this.artworkSmall = artworkSmall.url.replace(
        "artwork",
        `${this.blobServerUrl}/${this.showId}/${this.ind}/EPISODE_${this.episodeId}/artwork.1.dar`
      );
      this.artworkMedium = artworkMedium.url.replace(
        "artwork",
        `${this.blobServerUrl}/${this.showId}/${this.ind}/EPISODE_${this.episodeId}/artwork.1.dar`
      );

      if (this.player) this.player.poster(this.artworkMedium);

      const bioObj = find(
        this.show.episode.segment[this.currentSegment].cut.album.creativeArt,
        {
          type: "BIO",
        }
      );

      if (bioObj) {
        bioObj.urlClean = bioObj.url.replace("artwork", "artwork.1.dar");

        const bio = await axios.get(
          `${this.blobServerUrl}/${this.showId}/${this.ind}/EPISODE_${this.episodeId}/${bioObj.urlClean}`
        );
        this.bio = bio.data;
      } else {
        this.bio = null;
      }
    },
  },

  methods: {
    checkUnauthorized(err) {
      if (err.response && err.response.status) {
        if (err.response.status === 401) {
          this.$router.push({ name: 'Login', query: { redirect: window.location.href } });
        }
      }
    },

    async saveEpisodeDetails() {
      try {
        await axios.post(`/episode/${this.episode}`, {
          embargoTime: this.embargoTimePicker,
          expirationTime: this.expirationTimePicker
        }, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        // console.log(response.data);

        this.alert('The Episode Details have been updated successfully.');
      } catch (err) {
        console.error(err);
        this.checkUnauthorized(err);
        this.alert('The Episode Details update failed. Please try again.');
      }
    },

    setExpirationNow() {
      this.expirationTimePicker = dayjs().toISOString();
    },

    toggleBioExpand() {
      this.bioExpand = !this.bioExpand;

      // let found = false;
      // for (const item of this.$refs.bioExpand.classList) {
      //   if (item === 'expanded') {
      //     found = true;
      //     break;
      //   }
      // }

      // if (found) this.$refs.bioExpand.classList.value = this.$refs.bioExpand.classList.value.replace(' expanded', '');
      // else this.$refs.bioExpand.classList.value += ' expanded';
    },

    updateHasCaptions(hasCaptions) {
      this.hasCaptions = hasCaptions;

      this.captionsLoading = false;

      this.panels = this.hasCaptions ? [0, 1, 2] : [0, 2];
    },

    async updateCaptions(vttHandle) {
      if (!this.player) return;
      const textTracks = this.player.remoteTextTracks();
      // const foundTrack = find(textTracks.tracks_, { kind: "captions" }); //suddenly this isn't working, so let's manually iterate

      let foundTrack = false;
      for (const textTrack of textTracks.tracks_) {
        if (textTrack.kind === 'captions') foundTrack = textTrack;
        if (foundTrack) {
          this.player.removeRemoteTextTrack(foundTrack);
          break;
        }
      }

      this.player.addRemoteTextTrack(
        {
          src: `${window.__env.baseURL}/captions/vtt/handle/${vttHandle}`,
          kind: "captions",
          mode: "showing",
          srclang: "en",
          label: "English",
          default: true,
        },
        false
      );
    },

    alert(message) {
      this.dialogs.alert.message = message;
      this.dialogs.alert.state = true;
    },

    async confirmApprove(approveCC) {
      try {
        //approve the episode
        await axios.post(
          "/approve",
          {
            episodeId: this.episodeId,
            showId: this.showId,
          },
          {
            headers: {
              Authorization: "Bearer " + this.jwtToken,
              "X-Source": this.jwtSource
            }
          }
        );
        // console.log(response.data);

        //disable the approve/reject button
        this.approveButtonDisabled = true;

        this.cleanupConfirmDialog();

        this.alert('The episode was approved successfully.');

        //also approve the captions
        if (approveCC) this.bus.$emit('ccApprove');
      } catch (err) {
        console.error(err);
        this.checkUnauthorized(err);
        this.alert('The episode approval failed. Please try again.');
      }
    },

    async confirmReject() {
      this.$refs.rejectForm.validate();

      //validate form first
      if (!this.dialogs.reject.valid) return;

      // const confirm = window.confirm("Are you ready to reject this episode?");
      // if (confirm) {
      //reject the episode
      try {
        const reason = `[${this.dialogs.reject.rejectReason}] - ${this.dialogs.reject.rejectDetails}`;

        await axios.post(
          "/reject",
          {
            episodeId: this.episode.replace("EPISODE_", ""),
            showId: this.showId,
            rejectionInfo: reason,
          },
          {
            headers: {
              Authorization: "Bearer " + this.jwtToken,
              "X-Source": this.jwtSource
            },
          }
        );
        // console.log(response.data);

        this.cleanupRejectDialog();

        //disable the approve/reject button
        this.approveButtonDisabled = true;

        this.alert('The episode was rejected successfully.');
      } catch (err) {
        console.error(err);
        this.checkUnauthorized(err);
        this.alert('The episode rejection failed. Please try again.');
      }
      // }
    },

    cleanupConfirmDialog() {
      this.dialogs.confirm.state = false;
    },

    cleanupRejectDialog() {
      this.dialogs.reject.state = false;
      this.dialogs.reject.rejectDetails = null;
      this.dialogs.reject.rejectReason = null;
      this.$refs.rejectForm.resetValidation();
    },

    selectBitrate(level) {
      this.hls.selectLevel = level;
      const foundLevel = find(this.availableLevels, { index: level });
      window.localStorage.setItem(
        "preferredBitrate",
        foundLevel?.name || "AUTO"
      );

      const qualityLevels = this.player.qualityLevels();

      //iterate through bitrates and disable all but the one we want
      for (let i = 0; i < qualityLevels.length; i++) {
        qualityLevels[i].enabled = level === i || level === -1;
      }

      qualityLevels.selectedIndex_ = level;
      qualityLevels.trigger({ type: "change", selectedIndex: level });
    },

    checkSegment() {
      for (let i = 0; i < this.show.episode.segment.length; i++) {
        const nextSegment = this.show.episode.segment[i + 1];
        if (!nextSegment) break;
        const diff = dayjs(nextSegment.scheduling.startTime).diff(
          dayjs(this.show.episode.segment[0].scheduling.startTime),
          "second"
        );

        if (this.currentTime < diff) {
          this.currentSegment = i;
          break;
        }
      }
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },

    async loadEpisode(episode, show, ind) {
      try {
        this.loading = true;
        const xml = await axios.get(
          `${this.blobServerUrl}/${show}/${ind}/EPISODE_${episode}/EPISODE_${episode}.xml`
        );

        const parser = new XMLParser();
        let episodeData = parser.parse(xml.data);

        this.show.shortId = episodeData.onDemand.show.shortId;
        this.show.mediumTitle = episodeData.onDemand.show.mediumTitle;
        this.show.longTitle = episodeData.onDemand.show.longTitle;
        this.show.shortDescription = episodeData.onDemand.show.shortDescription;
        this.show.longDescription = episodeData.onDemand.show.longDescription;

        this.show.channel = episodeData.onDemand.show.channel;
        this.show.episode = episodeData.onDemand.show.series.episode;

        //fix for episodes with a single segment, being interpreted as an object rather than an array
        if (!isArray(this.show.episode.segment)) {
          this.show.episode.segment = [this.show.episode.segment];
        }

        this.currentSegment = 0;

        this.loading = false;

        await this.loadStream(this.episode, this.showId, this.ind);
      } catch (err) {
        this.checkUnauthorized(err);
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              this.snackbar.content = "Episode or show ID could not be found";
              break;
            default:
              this.snackbar.content = `An error occurred: ${err.message}`;
          }
        } else {
          this.snackbar.content = `An error occurred: ${err.message}`;
        }
        this.snackbar.color = "error";
        this.snackbar.state = true;
        this.loading = false;
      }
    },

    async loadStream(episode, show, ind) {
      this.streamLoading = true;

      const versionIdentifier = this.version > 1 ? `_v${this.version}` : "";
      let stream = `${this.blobServerUrl}/${show}/${ind}/EPISODE_${episode}/EPISODE_${episode}_variant_large${versionIdentifier}.m3u8`;

      this.videoOptions.sources = [
        {
          src: stream,
          type: "application/x-mpegURL",
        },
      ];

      //only call when we have a source added
      this.player = videojs(this.$refs.videoPlayer, this.videoOptions, () => {
        this.playerReady();

        //this appears to be redundant now, as we load captions using the vttHandle now from regenerateVTT in Captions.vue
        // this.player.addRemoteTextTrack(
        //   {
        //     src: `${window.__env.baseURL}/captions/vtt/${this.showId}/${this.ind}/EPISODE_${this.episodeId}?annotate=true`,
        //     kind: "captions",
        //     mode: "showing",
        //     srclang: "en",
        //     label: "English",
        //     default: true,
        //   },
        //   false
        // );
      });

      this.player.on("timeupdate", (e) => {
        if (e.target.player && !isFunction(e.target.player))
          this.currentTime = Math.floor(e.target.player.currentTime() || 0);
      });

      this.player.on("playing", () => {
        this.isPlaying = true;
      });

      this.player.on("pause", () => {
        this.isPlaying = false;
      });

      this.player.on("loadedmetadata", () => {
        this.playerReady();
      });

      // Listen to change events for when the player selects a new quality level
      const qualityLevels = this.player.qualityLevels();
      qualityLevels.on("change", () => {
        console.log(
          "Quality Level changed! New level:",
          qualityLevels[qualityLevels.selectedIndex]?.name || "AUTO"
        );
        this.hls.currentLevel = qualityLevels.selectedIndex;
      });
    },

    async selectCut(e, data) {
      this.currentSegment = data.index;

      const diff = dayjs(data.item.scheduling.startTime).diff(
        dayjs(this.show.episode.segment[0].scheduling.startTime),
        "second"
      );

      this.player.currentTime(diff ? diff : 0);
      this.player.play();
    },

    playerReady() {
      this.streamLoading = false;

      //get available levels
      const qualityLevels = this.player.qualityLevels();
      this.hls.levels = qualityLevels.levels_;

      //take level from URL if specified (this.bitrate), else user preference, else 64k
      const preferredBitrate = window.localStorage.getItem("preferredBitrate");
      if (!this.bitrate)
        this.bitrate = preferredBitrate ? preferredBitrate : "64k";

      const foundLevel = find(this.availableLevels, { name: this.bitrate });
      if (foundLevel) this.selectBitrate(foundLevel.index);
    },

    async play() {
      this.player.play();

      //send event on FIRST play only to AOD DB
      try {
        if (this.firstPlay) {
          await axios.post(`/event/play?showId=${this.showId}&episodeId=${this.episodeId}`, {
            embargoTime: this.embargoTimePicker,
            expirationTime: this.expirationTimePicker
          }, {
            headers: {
              Authorization: "Bearer " + this.jwtToken,
              "X-Source": this.jwtSource
            }
          });
        }

        this.firstPlay = false;
      } catch (err) {
        console.error(err);
      }
    },

    pause() {
      this.player.pause();
    },

    backward() {
      //constrain to min segments
      if (this.currentSegment > 0) this.currentSegment--;

      const diff = dayjs(
        this.show.episode.segment[this.currentSegment].scheduling.startTime
      ).diff(
        dayjs(this.show.episode.segment[0].scheduling.startTime),
        "second"
      );
      this.player.currentTime(diff ? diff : 0);
      this.player.play();
    },

    forward() {
      //constrain to max segments
      if (this.currentSegment < this.totalSegments - 1) this.currentSegment++;

      const diff = dayjs(
        this.show.episode.segment[this.currentSegment].scheduling.startTime
      ).diff(
        dayjs(this.show.episode.segment[0].scheduling.startTime),
        "second"
      );
      this.player.currentTime(diff ? diff : 0);
      this.player.play();
    },

    rewind() {
      this.player.currentTime(this.player.currentTime() - 10);
    },

    fastForward() {
      this.player.currentTime(this.player.currentTime() + 10);
    },

    seek(value) {
      this.player.currentTime(value || 0);
    },
  },

  async mounted() {
    axios.defaults.baseURL = window.__env.baseURL;
    this.jwtToken = this.$store.state.jwtToken;
    this.jwtSource = this.$store.state.jwtSource;
    this.bitrate = this.$route.query.bitrate;
    this.version = this.$route.query.version || 2;
    this.videoOptions.autoplay = this.autoPlay;

    this.captionsLoading = true;

    this.panels = this.hasCaptions ? [0, 1, 2] : [0, 2];

    onKeyUp(" ", (e) => {
      e.preventDefault();
      // if (this.isPlaying) this.player.pause();
      // else this.player.play();
    });

    await this.loadEpisode(this.episode, this.showId, this.ind);

    this.approveButtonDisabled = (this.episodeState !== 5);

    this.embargoTimePicker = this.embargoTime;
    this.expirationTimePicker = this.expirationTime;

    if (!this.enableCaptions) this.updateHasCaptions(false);
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
