<template>
    <v-container fluid :fill-height="!showId || !ind || !episode" class="pa-0">
        <!-- <QAPlayerLegacy :dark-mode="darkMode" :showId="showId" :ind="ind" :episode="episode"
            v-if="showId && ind && episode && legacyPlayer" /> -->

        <QAPlayer :dark-mode="darkMode" :showId="showId" :ind="ind" :episode="episode" :referenceId="referenceId" :episodeState="episodeState"
            :episodeStateCode="episodeStateCode" :episodeStateDesc="episodeStateDesc"
            :episodePublishedBy="episodePublishedBy" :airStartTime="airStartTime" :airEndTime="airEndTime"
            :embargoTime="embargoTime" :expirationTime="expirationTime" :ccRequired="ccRequired"
            v-if="showId && ind && episode && !legacyPlayer && !hasError" />

        <v-container v-else>
            <div class="d-flex flex-grow-1 flex-column fill-height align-center justify-center">
                <!-- <v-progress-circular indeterminate size="120" color="accent2" v-if="episodeLoading"></v-progress-circular> -->
                <!-- <div class="text-uppercase mt-5">Loading</div> -->

                <v-img src="@/assets/stella_large.gif" contain max-width="250"></v-img>
            </div>

            <v-alert type="error" icon="mdi-alert" border="left" prominent v-if="alert.state">
                {{ alert.message }}
            </v-alert>
        </v-container>
    </v-container>
</template>

<script>
import axios from 'axios';
// import QAPlayerLegacy from '@/components/QAPlayerLegacy.vue';
import QAPlayer from '@/components/QAPlayer.vue';

let intervalEpisode;

export default {
    name: 'Home',

    props: ['darkMode', 'user'],

    components: {
        // QAPlayerLegacy,
        QAPlayer
    },

    data: () => ({
        jwtToken: null,
        showId: null,
        ind: null,
        episodeLoading: false,
        episode: null,
        referenceId: null,
        episodeState: null,
        episodeStateCode: null,
        episodeStateDesc: null,
        episodePublishedBy: null,
        airStartTime: null,
        airEndTime: null,
        embargoTime: null,
        expirationTime: null,
        ccRequired: null,
        alert: {
            state: false,
            message: null,
        },
        hasError: false,
    }),

    computed: {
        legacyPlayer() {
            return this.$route.query.legacy &&
                this.$route.query.legacy !== 'false' &&
                this.$route.query.legacy !== 'f' &&
                this.$route.query.legacy !== '0';
        }
    },

    methods: {
        async lookupEpisode() {
            try {
                this.alert.state = false;
                this.episodeLoading = true;

                this.episode = this.$router.currentRoute.params.episode.replace("EPISODE_", "");
                this.ind = this.$router.currentRoute.params.ind || 1;

                const response = await axios.get(`/episode/${this.episode}`, {
                    headers: {
                        Authorization: "Bearer " + this.jwtToken,
                        "X-Source": this.jwtSource
                    }
                });

                this.showId = response.data.episode.Show_Id || this.$router.currentRoute.params.showId;
                this.referenceId = response.data.episode.ReferenceId;
                this.episodeState = response.data.episode.EpisodeState_Id;
                this.episodeStateCode = response.data.episode.StatusType_Code;
                this.episodeStateDesc = response.data.episode.StatusType_Desc;
                this.episodePublishedBy = response.data.episode.PublishedBy;
                this.airStartTime = response.data.episode.AirStartDateTime;
                this.airEndTime = response.data.episode.AirEndDateTime;
                this.embargoTime = response.data.episode.EmbargoDateTime;
                this.expirationTime = response.data.episode.ExpirationTimestamp;

                this.episodeLoading = false;
            } catch (err) {
                this.episodeLoading = false;
                this.checkUnauthorized(err);
                console.error(err);
                // alert(`Unable to fetch episode details: ${err.message}`);

                this.alert.state = true;
                this.alert.message = `Unable to fetch episode details: ${err.message}`;

                if (err.response?.status === 404) {
                    // alert('The requested episode does not exist.');

                    this.alert.message = 'The requested Episode ID does not exist';
                    this.hasError = true;
                }

                this.episode = this.$router.currentRoute.params.episode.replace("EPISODE_", "");
                this.showId = this.$router.currentRoute.params.showId;
                this.ind = this.$router.currentRoute.params.ind || 1;

                // if an error occurred, let's stop the automatic refreshes
                if (intervalEpisode) clearInterval(intervalEpisode);
            }
        },

        async lookupCCProcess() {
            try {
                const response = await axios.get(`/ccprocess/${this.episode}`, {
                    headers: {
                        Authorization: "Bearer " + this.jwtToken,
                        "X-Source": this.jwtSource
                    }
                });

                this.ccRequired = response.data.ccProcess.Episode_Id === parseInt(this.episode);
            } catch (err) {
                this.checkUnauthorized(err);
                console.error(err);
            }
        },

        checkUnauthorized(err) {
            if (err.response && err.response.status) {
                if (err.response.status === 401) {
                    this.$router.push({ name: 'Login', query: { redirect: window.location.href } });
                }
            }
        }
    },

    async mounted() {
        axios.defaults.baseURL = window.__env.baseURL;
        this.jwtToken = this.$store.state.jwtToken;
        this.jwtSource = this.$store.state.jwtSource;

        await this.lookupEpisode();

        if (intervalEpisode) clearInterval(intervalEpisode);

        //periodically refresh the episode state
        // if (this.episodeStateCode !== 'Published' && this.episodeStateCode !== 'Expired' && this.episodeStateCode !== 'Offline') {
        intervalEpisode = setInterval(async () => {
            await this.lookupEpisode();
        }, window.__env.episodeRefreshTimer || 5000);
        // }

        if (window.__env.enableCaptions) await this.lookupCCProcess();
    }
}
</script>
